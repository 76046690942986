import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                    }
                }
            }
        `
    )
    const metaDescription = description || site.siteMetadata.description
    const metaLang = lang || "en"
    const metaTags = meta || []
    const metaTitle = title || site.siteMetadata.title
    const titleTemplate = title ? `%s | ${site.siteMetadata.title}` : `%s`
    return (
        <Helmet
            htmlAttributes={{
                lang: metaLang
            }}
            title={metaTitle}
            titleTemplate={titleTemplate}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                }
            ].concat(metaTags)}
        />
    )
}

export default SEO
